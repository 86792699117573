<template>
  <div>
   <div class="container commonDetail">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/Mine/MoneyBag' }">钱包</el-breadcrumb-item>
      <el-breadcrumb-item>账单详情</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="commonWhiteBg">
        <div class="commonTitle"> 账单详情 </div>
          <el-form
          ref="tableDetail"
          :model="tableDetail"
          label-width="140px"
        >
          <el-form-item
            label="交易类型"
            prop="bizType"
          >
            {{tableDetail.bizTypeText}}
          </el-form-item>
           <el-form-item
            label="当前状态"
            prop="paymentStatus"
          >
            {{tableDetail.paymentStatusText}}
          </el-form-item>
          <template>
               <el-form-item
            label="商品名称"
            prop="subject"
          >
            {{tableDetail.subject}}
          </el-form-item>
           <el-form-item
            label="支付金额"
            prop="paidAmount"
          >
            {{tableDetail.paidAmount}}
          </el-form-item>
          <el-form-item
            label="支付方式"
            prop="paymode"
          >
            {{tableDetail.paymode}}
          </el-form-item>
           <el-form-item
            label="订单号"
            prop="orderNo"
          >
            {{tableDetail.orderNo}}
          </el-form-item>
           <!-- <el-form-item
            label="服务费"
            prop="value6"
          >
            {{form.value6}}
          </el-form-item> -->
           <el-form-item
            label="创建时间"
            prop="createTime"
          >
            {{tableDetail.createTimeText}}
          </el-form-item>
          </template>
          <template v-if="orderType==2">
                  <el-form-item
            label="提现银行"
            prop="value8"
          >
            {{form.value8}}
                  </el-form-item>
                  <el-form-item
                    label="收款人"
                    prop="value9"
                  >
                    {{form.value9}}
                  </el-form-item>
                  <el-form-item
                    label="提现金额"
                    prop="value10"
                  >
                    {{form.value10}}
                  </el-form-item>
                  <el-form-item
                    label="服务费"
                    prop="value11"
                  >
                    {{form.value11}}
                  </el-form-item>
                  <el-form-item
                    label="申请时间"
                    prop="value12"
                  >
                    {{form.value12}}
                  </el-form-item>
                  <el-form-item
                    label="到账时间"
                    prop="value13"
                  >
                    {{form.value13}}
                  </el-form-item>
                  <el-form-item
                    label="提现单号"
                    prop="value14"
                  >
                    {{form.value14}}
                  </el-form-item>
          </template>
          </el-form>
    </div>
   </div>
  </div>
</template>

<script>
export default {
  name: 'OrderDetail',

  data () {
    return {
      orderType:1,
      // form:{
      //   value1:'合约挂卖',
      //   value2:'交易成功',
      //   value3:'挂卖-《三体全集》',
      //   value4:'¥99',
      //   value5:'钱包-129389012803981',
      //   value6:'1',
      //   value7:'1208130281038021830808',
      //    value8:'建设银行',
      //   value9:'张三',
      //   value10:'¥100',
      //   value11:'0',
      //   value12:'2019-12-23 14:07',
      //   value13:'2019-12-23 18:07',
      //   value14:'1208130281038021830808',
      // }
    }
  },
  created() {
    this.tableDetail = this.$route.query.tableDetail;
    console.log(this.tableDetail);
  },
  methods: {
 
    
  }
}
</script>

<style lang='scss' scoped>

</style>
